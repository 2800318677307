import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { deleteDownload, editDownload, getDownloads, getUniqueFieldValues, getDownload } from '../../handlers/downloads'
import { useSearchParams, useNavigate } from 'react-router-dom'
import TableLoader from '../../components/Loaders/TableLoader'
import ReactPaginate from 'react-paginate'
import { initializeWorker, terminateWorker } from '../../handlers/workers'
import { ArrowContainer, Popover } from 'react-tiny-popover'

import './Downloads.css'

export default function Downloads(){
    const navigate = useNavigate()
    const [queryParams] = useSearchParams()

    const [isLoading, setIsLoading] = useState(false)
    const [downloads, setDownloads] = useState([])
    const [initialFilter, setInitialFilter] = useState()
    const [filters, setFilters] = useState({ limit: 15, page: 1, importName: '', status: '', batchId: '', category: '', user: '' })
    const [filterOptions, setFilterOptions] = useState({ category: [], user: [], status: [] })
    const [pageCount, setPageCount] = useState(1)
    const [toggleUpdate, setToggleUpdate] = useState(false)
    const [activePopover, setActivePopover] = useState('')
    const [totalBatchCount, setTotalBatchCount] = useState(0)

    const handleGetDownloads = async (flag) => {
        flag ? setIsLoading(true) : setIsLoading(false)
    
        try {
            const searchParams = new URLSearchParams(window.location.search)
            const limit = parseInt(searchParams.get('limit')) || 15
            const page = parseInt(searchParams.get('page')) || 1
            const importName = searchParams.get('importName') || ''
            const status = searchParams.get('status') || ''
            const batchId = searchParams.get('batchId') || ''
            const category = searchParams.get('category') || ''
            const user = searchParams.get('user') || ''
    
            const data = await getDownloads(limit, page, importName, status, batchId, category, user)
            setDownloads(data.result)
            setTotalBatchCount(data.totalCount)
            setPageCount(Math.ceil(data.totalCount / limit))
        } catch (error) {
            toast.error(error.message || 'An error occurred')
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }    

    const handleGetUniqueFieldValues = async() => {
        const categoryOptions = await getUniqueFieldValues('category')
        const userOptions = await getUniqueFieldValues('user')
        const statusOptions = await getUniqueFieldValues('status')

        setFilterOptions({ category: categoryOptions, user: userOptions, status: statusOptions })
    }

    const updateQueryParams = (newFilters, pageUpdate) => {
        const searchParams = new URLSearchParams()
        Object.entries(newFilters).forEach(([key, value]) => {
            if (key === 'page' && pageUpdate) {
                searchParams.append(key, pageUpdate)
            }
            else {
                searchParams.append(key, value)
            }
        })
        
        navigate(`/downloads?${searchParams.toString()}`)
    }

    const handleSearch = () => {
        updateQueryParams(filters, 1)
    }

    const handleClear = () => {
        updateQueryParams(initialFilter)
        setFilters(initialFilter)
        toast.info('All filters cleared!')
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }))
    }

    const handlePageUpdate = (page) => {
        const updatedFilters = { ...filters, page: page.selected + 1 }
        setFilters(updatedFilters)
        updateQueryParams(updatedFilters, page.selected + 1)
    }

    const setInitialFiltersFromQueryParams = () => {
        const params = queryParams.entries()
        let initialFilters = {}
        for (const [key, value] of params) {
            initialFilters[key] = value
        }
        setInitialFilter(initialFilters)
    }

    const handleDownload = (download, flag) => {
        const file = flag ? download.outputFile : download.originalFile
        const downloadUrl = `https://demand-data-tools.s3.amazonaws.com/${file}`
        const anchor = document.createElement('a')
        anchor.href = downloadUrl
        anchor.setAttribute('download', '')
        anchor.click()
    }

    const handleCancel = async (download) => {
        if (!window.confirm(`Are you sure you want to cancel Batch ${download.batchId}?`)) return
        setIsLoading(true)

        const body = {
            status: 'Cancelled'
        }
        const response = await editDownload(download.batchId, body)
        await terminateWorker({ batchId: download.batchId })

        if(response.download) {
            setToggleUpdate(!toggleUpdate)
            toast.success(`Batch ${download.batchId} successfully cancelled`)
        } else {
            toast.error(`Cancellation error`)
        }

        setIsLoading(false)
    }

    const handleResubmit = async (download) => {
        if (!window.confirm(`Resubmit Batch ${download.batchId}?`)) return
        setIsLoading(true)

        const body = {
            status: 'Ongoing'
        }
        await editDownload(download.batchId, body)

        await initializeWorker(download)
        setToggleUpdate(!toggleUpdate)
        toast.success('Re-Submitted!')

        setIsLoading(false)
    }

    const handleDelete = async (download) => {
        if (!window.confirm(`Are you sure you want to delete Batch ${download.batchId}?`)) return
        setIsLoading(true)

        await terminateWorker({ batchId: download.batchId })
        const response = await deleteDownload(download.batchId)

        if(response.download) {
            setToggleUpdate(!toggleUpdate)
            toast.success(`Batch ${download.batchId} successfully deleted`)
        } else {
            toast.error(`Deletion error`)
        }
        
        setIsLoading(false)
    }

    const getStatusBadgeClassName = (status) => {
        switch (status) {
            case 'Completed':
                return 'bg-success'
            case 'Failed':
                return 'bg-danger'
            case 'Cancelled':
                return 'bg-secondary'
            case 'Submitted':
                return 'bg-info'
            case 'Ongoing':
                return 'bg-warning'
            default:
                return 'bg-dark'
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        date.setUTCHours(date.getUTCHours())
        
        const options = {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Asia/Manila' 
        }
        
        return new Intl.DateTimeFormat('en-US', options).format(date)
    }

    const toggleActivePopover = async(batchId) => {
        const response = activePopover === batchId ? '' : await getDownload(batchId)
        setActivePopover(response?.download?.progress ? batchId : '')
    }

    const handlePageCountChange = (e) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            limit: e.target.value
        }))
        setToggleUpdate(!toggleUpdate)
    }

    useEffect(() => {
        handleGetUniqueFieldValues()
        setInitialFiltersFromQueryParams()

        const intervalId = setInterval(() => {
            handleGetDownloads(0)
        }, 10000)

        return () => clearInterval(intervalId)

    },[toggleUpdate])

    useEffect(() => {
        handleGetDownloads(1)
    },[queryParams, toggleUpdate])

    return (
        <div className='x_downloads-container'>
            <div className='x_filters-container'>
                <div className='x_filters-container-2'>
                    <input name='importName' type='text' className='x_input-container me-4 form-control x_textAlignCenter' placeholder='Import Name' value={filters.importName} onChange={handleInputChange}/>
                    <select name='category' className='x_input-container me-4 form-select x_textAlignCenter x_cursorPointer' value={filters.category} onChange={handleInputChange}>
                        <option value=''>_Select Type_</option>
                        {filterOptions.category.map((category, index) => (
                            <option key={index} value={category}>{category}</option>
                        ))}
                    </select>
                    <input name='batchId' type='text' className='x_input-container me-4 form-control x_textAlignCenter' placeholder='Batch ID' value={filters.batchId} onChange={handleInputChange}/>
                    <select name='user' className='x_input-container me-4 form-select x_textAlignCenter x_cursorPointer' value={filters.user} onChange={handleInputChange}>
                        <option value=''>_Select Requester_</option>
                        {filterOptions.user.map((user, index) => (
                            <option key={index} value={user}>{user}</option>
                        ))}
                    </select>
                    <select name='status' className='x_input-container me-4 form-select x_textAlignCenter x_cursorPointer' value={filters.status} onChange={handleInputChange}>
                        <option value=''>_Select Status_</option>
                        {filterOptions.status.map((status, index) => (
                            <option key={index} value={status}>{status}</option>
                        ))}
                    </select>
                    <button type='button' className='me-2 btn btn-info x_search-button' onClick={handleSearch}>
                        {!isLoading ? <i className='fa-solid fa-magnifying-glass'/> : <i className='fa-solid fa-spinner fa-spin-pulse'/>}
                    </button>
                    <button type='button' className='btn btn-info x_clear-button' onClick={handleClear}>
                        {!isLoading ? <i className='fa-solid fa-xmark'/> : <i className='fa-solid fa-spinner fa-spin-pulse'/>}
                    </button>
                </div>
            </div>
            <div className='x_table-wrapper'>
                <div className='x_table-header'>
                    <table className='x_table table table-hover'>
                        <thead className='x_thead'>
                            <tr className='x_textAlignCenter'>
                                {tableHeaders.map((header, headerIndex) => (
                                    <th className='lilita-one-regular' key={headerIndex} style={{width: header.width, color: '#06514E'}}>{header.label}</th>
                                ))}
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className={!isLoading ? '' : 'x_table-body'}>
                    {
                        !isLoading ?
                        <table className='x_table table table-hover'>
                            <tbody className='x_tbody'>
                                    { downloads.map((download, downloadIndex) => (
                                        <tr key={downloadIndex} className='x_tbody-tr x_textAlignCenter'>
                                            <td style={{width: tableHeaders[0].width}}>{(downloadIndex+1)+(filters.limit*(filters.page-1))}</td>
                                            <td style={{ width: tableHeaders[1].width, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{download.importName}</td>
                                            <td style={{width: tableHeaders[2].width}}>{download.category}</td>
                                            <td style={{width: tableHeaders[3].width, padding: 0, alignContent: 'center'}}>
                                                <span onClick={()=>handleDownload(download, false)} className='x_badge2 badge bg-info'>{download.batchId}</span>
                                            </td>
                                            <td style={{width: tableHeaders[4].width, color: '#2B7A77'}}>
                                                <u>{download.user}</u>
                                            </td>
                                            <td style={{width: tableHeaders[5].width}}>
                                                <em>{formatDate(download.createdAt)}</em>
                                            </td>
                                            <td style={{width: tableHeaders[6].width}}>
                                                <em>{formatDate(download.updatedAt)}</em>
                                            </td>
                                            <td style={{width: tableHeaders[7].width, padding: 0, alignContent: 'center'}}>
                                                <Popover
                                                    isOpen={download.batchId === activePopover}
                                                    positions={['top']}
                                                    onClickOutside={() => setActivePopover('')}
                                                    content={({ position, childRect, popoverRect }) => (
                                                        <ArrowContainer 
                                                            position={position}
                                                            childRect={childRect}
                                                            popoverRect={popoverRect}
                                                            arrowColor={'blue'}
                                                            arrowSize={6}
                                                            arrowStyle={{ opacity: 0.7 }}
                                                            className='popover-arrow-container'
                                                            arrowClassName='popover-arrow'
                                                        >
                                                            <div className='x_popover_content'>{`Progress: ${download.progress?.current || 0} / ${download.progress?.total || 0}`}</div>
                                                        </ArrowContainer>
                                    )}
                                                >
                                                    <div className='x_popover_div' onClick={() => toggleActivePopover(download.batchId)}>
                                                        <span className={`x_badge badge rounded-pill ${getStatusBadgeClassName(download.status)}`}>{download.status}</span>
                                                    </div>
                                                </Popover>
                                            </td>
                                            <td style={{ width: tableHeaders[8].width, padding: 0 , alignContent: 'center'}}>
                                                {(() => {
                                                    switch (download.status) {
                                                        case 'Completed':
                                                            return (
                                                                <button onClick={()=>handleDownload(download, true)} type='button' className='btn btn-info btn-sm x_action-button1'>
                                                                    <i className='fa-solid fa-download' /> Download
                                                                </button>
                                                            )
                                                        case 'Failed':
                                                        case 'Cancelled':
                                                                return (
                                                                <button onClick={()=>handleResubmit(download)} type='button' className='btn btn-outline-info btn-sm x_action-button1'>
                                                                    <i className='fa-solid fa-ban' /> Resubmit
                                                                </button>
                                                            )
                                                        case 'Submitted':
                                                        case 'Ongoing':
                                                            return (
                                                                <button onClick={()=>handleCancel(download)} type='button' className='btn btn-outline-danger btn-sm x_action-button1'>
                                                                    <i className='fa-solid fa-ban' /> Cancel
                                                                </button>
                                                            )
                                                        default:
                                                            return null
                                                    }
                                                })()}
                                                <button onClick={()=>handleDelete(download)} type='button' className='x_action-button2 btn btn-outline-danger btn-sm'>
                                                    <i className='fa-solid fa-trash-can'></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )) }
                            </tbody>
                        </table> :
                        <TableLoader/>
                    }
                </div>
            </div>
            <div className='x_pagination-container'>
                {isLoading ? '' :
                    <React.Fragment>
                        <ReactPaginate
                            nextLabel='Next'
                            onPageChange={handlePageUpdate}
                            pageRangeDisplayed={pageCount > 3 ? 3 : 1}
                            marginPagesDisplayed={pageCount > 3 ? 3 : 1}
                            pageCount={pageCount > 10 ? 10 : pageCount}
                            previousLabel='Previous'
                            pageClassName='page-item'
                            pageLinkClassName='page-link x_page-link'
                            previousClassName='page-item'
                            previousLinkClassName='page-link x_page-link-2'
                            nextClassName='page-item'
                            nextLinkClassName='page-link x_page-link-3'
                            breakLabel='...'
                            breakClassName='page-item'
                            breakLinkClassName='page-link x_page-link'
                            containerClassName='pagination'
                            activeClassName='active'
                            renderOnZeroPageCount={null}
                            initialPage={filters.page - 1}
                        />
                        <div className='x_pagination-info'>Showing {(filters.limit*(filters.page-1))+1}-{(filters.limit*(filters.page-1))+Number(filters.limit)} of {totalBatchCount} Batches
                            <select className='x_pagination-pagecount' onChange={handlePageCountChange} value={filters.limit}>
                                <option value={15}>15</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

const tableHeaders = [
    {label: '#', width: '4%'},
    {label: 'Import Name', width: '12%'},
    {label: 'Type', width: '10%'},
    {label: 'Batch ID', width: '12%'},
    {label: 'Requester', width: '14%'},
    {label: 'Request Date', width: '14%'},
    {label: 'Date Updated', width: '14%'},
    {label: 'Status', width: '10%'},
    {label: 'Actions', width: '10%'},
]