import React, { useEffect } from 'react'
import ToolContainer from '../../components/Containers/ToolContainer'
import TemplateParser from './Tools/TemplateParser'
import DdParser from './Tools/DdParser'
import { toast } from 'react-toastify'

import './MainPage.css'
import LegacyV2 from './Tools/LegacyV2'
// import CsddGenerator from './Tools/CsddGenerator'

export default function MainPage(){
    useEffect(()=>{
        toast.info('Always check for updates here⬇️', {
            autoClose: 60000,
            theme: 'colored',
        })
    },[])

    return (
        <div className='x_body-container'>
            <ToolContainer title={'DD Report to DD Template'} toolName={'template-parser'}>
                <TemplateParser />
            </ToolContainer>
            <ToolContainer title={'CS DD to DD Report'} toolName={'dd-parser'}>
                <DdParser />
            </ToolContainer>
            <ToolContainer title={'Data Central Mini'} toolName={'legacy-v2'}>
                <LegacyV2 />
            </ToolContainer>
            {/* <ToolContainer title={'CS DD Generator'} toolName={'csdd-generator'} noConfig={true}>
                <CsddGenerator />
            </ToolContainer> */}
        </div>
    )
}