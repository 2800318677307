import { makeApiRequest } from "./_index"

export async function getAccountDetails(api_key) {
  const baseUrl = `https://api.scraperapi.com/account`
  const queryString = [
      `api_key=${api_key}`,
  ]
  const url = `${baseUrl}?${queryString.join('&')}`
  const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
  }

  return makeApiRequest(url, options)
}