import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { uploadFileToS3 } from '../../../middlewares/AWS'
import { addDownload } from '../../../handlers/downloads'
import { useNavigate } from 'react-router-dom'

import './Tools.css'
import { initializeWorker } from '../../../handlers/workers'
import { getAccountDetails } from '../../../handlers/scraperApi'

export default function LegacyV2() {
  const isQA = process.env.REACT_APP_NODE_ENV === 'development'
  const navigate = useNavigate()

  const [isLoading, setIsloading] = useState(false)
  const [isLoading2, setIsloading2] = useState(false)
  const [inputs, setInputs] = useState({
    marketplace: 'ATVPDKIKX0DER',
    needReviews: false,
    importFile: null,
    importName: ''
  })
  const [remainingRequests, setRemainingRequests] = useState(0)

  const handleSelectChange = (event) => {
    const { id, value } = event.target
    setInputs(prevState => ({ ...prevState, [id]: value }))
  }

  const handleFileInputChange = (event) => {
    const file = event.target.files[0]
    setInputs(prevState => ({ ...prevState, importFile: file }))
  }

  const handleTextInputChange = (event) => {
    const { id, value } = event.target
    setInputs(prevState => ({ ...prevState, [id]: value }))
  }

  const handleSubmit = async () => {
    console.log('Form submitted with inputs:', inputs)
    setIsloading(true)

    if (!inputs.importFile) {
      toast.error('No file selected.')
      setIsloading(false)
      return
    } 
    
    const batchId = `DDT-${Date.now()}${isQA ? ' (QA)' : ''}`
    const keyName = await uploadFileToS3('imports', inputs.importFile, `(${batchId})${inputs.importFile.name}`)
    const requestBody = {
      batchId,
      category: 'legacy-v2',
      importName: inputs.importName,
      user: localStorage.getItem('email'),
      originalFile: keyName,
      options: inputs,
    }

    console.log(requestBody)

    const response = await addDownload(requestBody)
    await initializeWorker(response.download)
    navigate('/downloads')

    toast.success('Submitted!')
    setIsloading(false)
  }

  const getScraperApiCredits = async() => {
    setIsloading2(true)
    const api_key = process.env.REACT_APP_SCRAPER_API_KEY
    const response = await getAccountDetails(api_key)
    setRemainingRequests(((response.requestLimit - response.requestCount)/5) || 0)
    setIsloading2(false)
  }

  useEffect(() => {
    getScraperApiCredits()
    const intervalId = setInterval(() => {
      getScraperApiCredits()
    }, 30000)
  
    return () => clearInterval(intervalId)
  }, [])

  return (
    <React.Fragment>
      <div className='x_card-body x_card-body2 card-body'>
        <div className='x_select-mainContainer'>
          <div className='x_select-container2'>
            <label htmlFor='marketplace' className='x_label form-label'>Marketplace:</label>
            <select className='x_form-select form-select' id='marketplace' onChange={handleSelectChange} value={inputs.marketplace}>
              <option value='ATVPDKIKX0DER'>US</option>
              <option value='A2EUQ1WTGCTBG2'>CA</option>
            </select>
          </div>
          <div className='x_select-container2'>
            <label htmlFor='needReviews' className='x_label form-label'>Use Reviews API?</label>
            <select className='x_form-select form-select' id='needReviews' onChange={handleSelectChange} value={inputs.needReviews}>
              <option value={false}>NO</option>
              <option value={true}>YES</option>
            </select>
          </div>
          <div className='x_select-container2'>
            <label htmlFor='remainingCredits' className='x_label x_label2 form-label'>Remaining Reviews API Calls:
              <span className='x_credits'>{isLoading2 ? '-' : remainingRequests}</span>
            </label>
          </div>
        </div>
        <div className='x_fileInput-container'>
          <label className='x_label form-label'>Upload File:</label>
          <input className='x_fileInput form-control' type='file' id='fileInput1' accept='.xlsb, .xlsx' onChange={handleFileInputChange}/>
        </div>
        <div className='x_textInput-container'>
          <label className='x_label form-label'>Import Name: </label>
          <input type='text' className='x_outputFile form-control' placeholder='Please input desired file name' id='importName' onChange={handleTextInputChange}/>
        </div>
        
      </div>
      <div className='x_card-footer card-footer'>
        <button id='parseButton1' className='x_btn btn btn-primary btn-sm'
          onClick={handleSubmit}
          disabled={isLoading ? true : false}
        >{isLoading ? <i className="fa-solid fa-spinner fa-spin-pulse"></i> : 'Submit'}</button>
      </div>
    </React.Fragment>
  )
}